<template>
    <div class="control">
        <div class="good-box">
            <div class="good-box-1">
                <div class="good-banner">
                    <van-image width="100%" height="100%" :src="detailData.good_pic" fit="cover"/>
                </div>
                <div class="good-info">
                    <van-skeleton :loading="loading" :row="4" style="width: 100%">
                        <div class="good-title">
                            {{ detailData.title }}
                        </div>
                        <div class="good-price flex">
                            <div class="price-1">
                                ¥{{ this.selectValue ? detailData.price : detailData.price_show }}
                            </div>
                            <div class="price-2">
                                <strong>¥{{ getPrice.num1 }}</strong>
                                <span>.{{ getPrice.num2 }}</span>
                            </div>
                        </div>
                    </van-skeleton>

                </div>
            </div>
            <div class="good-box-2 flex-between">
                <div class="label">购买数量</div>
                <van-stepper v-model="num"
                             step="1"
                             :disabled="detailData.goods_type === 2"
                             min="1"
                             button-size="25px"
                             :max="detailData.buy_limit == 0 ? 99 : detailData.buy_limit"/>
            </div>
        </div>

        <div class="good-section">
            <div class="good-section-title">用户信息</div>
            <div class="good-section-con">
                <van-form ref="formRef">
                    <van-field
                        v-model="userForm.name"
                        name="真实姓名"
                        maxlength="7"
                        label="真实姓名"
                        placeholder="请填写真实姓名"
                        :rules="[{ required: true, message: '请填写真实姓名' }]"
                    />
                    <van-field
                        v-model="userForm.tel"
                        name="联系电话"
                        label="联系电话"
                        type="number"
                        inputmode="numeric"
                        placeholder="请填写联系电话"
                        maxlength="11"
                        :rules="[{ pattern: /^1\d{10}$/, message: '请填写正确的手机号' }]"
                    />
                </van-form>
            </div>
        </div>
        <div class="good-section">
            <div class="good-section-title">订单信息</div>
            <div class="good-section-con">
                <div class="good-info-item flex-between">
                    <div class="label">商品金额</div>
                    <div class="value">
                        ¥<strong style="font-size: 17px">{{ this.selectValue ? detailData.qyk_price : detailData.price }}</strong></div>
                </div>
                <div class="good-info-item flex-between" v-if="selectValue">
                    <div class="label">特检卡会员专享</div>
                    <div class="value flex" style="color: #ED2525">
                        <div class="benefit-card-title flex" style="margin-right: 8px">
                            <img src="https://keyang2.tuzuu.com/klfront/31c97d8ab1e2e14b5a9f182603b951bb.png"
                                 class="benefit-card-icon"/>
                            <div>特检会员</div>
                        </div>
                        <div>- ¥<strong style="font-size: 17px">{{ detailData.sale_off_price }}</strong></div>
                    </div>
                </div>

                <div class="price-amount">
                    合计：<span>¥</span><strong>{{ getPrice.num1 }}.{{ getPrice.num2 }}</strong>
                </div>

            </div>
        </div>

        <div class="benefit-card" v-if="detailData.is_activation == 0 && detailData.is_sale_off === 1"
             @click="selectRadio">
            <div class="benefit-card-title flex">
                <img src="https://keyang2.tuzuu.com/klfront/31c97d8ab1e2e14b5a9f182603b951bb.png"
                     class="benefit-card-icon"/>
                <div>特检会员</div>
            </div>
            <div class="benefit-con flex">
                <van-image width="54" height="54" fit="cover" :src="benefitData.good_pic"/>
                <div class="benefit-con-right">
                    <div class="flex-between">
                        <div class="benefit-con-1">{{ benefitData.title }}</div>
                        <div class="benefit-con-2">成为会员减 {{ detailData.sale_off_price }}元</div>
                    </div>
                    <div class="benefit-con-3 flex-between">
                        <div class="benefit-con-info flex ">
                            <div class="benefit-price-1">￥<strong>{{ benefitData.price }}</strong></div>
                        </div>
                        <van-checkbox v-model="selectValue" checked-color="#319D67" ref="checkboxes"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box flex-between">
            <div>
                <div class="footer-1">
                    合计：<span>¥
                        <strong>{{ getTotalPrice.num1 }}</strong>
                        <span style="font-weight: 700">.{{ getTotalPrice.num2 }}</span>
                    </span>
                </div>
                <div class="footer-2" v-if="selectValue">
                    优惠：¥{{ (Number(detailData.sale_off_price) * 100 * num / 100).toFixed(2) }}
                </div>
            </div>
            <div class="footer-btn" @click="goPay">
                提交订单
            </div>
        </div>
    </div>
</template>

<script>
import wxShare from "@/wechat/weChatTile";
import {weChatPay} from "@/wechat/weChatPay";

export default {
    data() {
        return {
            num: 1,
            loading: true,
            throt: true,
            detailData: {
                price: ''
            },
            benefitData: {},
            userForm: {
                name: '',
                tel: ''
            },
            selectValue: false
        }
    },
    created() {
        Promise.all([
            axios.get('/qykshop/good_detail', {params: {goods_id: this.$route.params.id}}),
            axios.get("/qykshop/get_qyk_good_info")
        ]).then(res => {
            if (res[0].code === 1) {
                this.detailData = res[0].data
                // this.detailData.is_activation = 1
                this.loading = false
                if (this.detailData.is_activation == 1) {
                    this.selectValue = true
                }
                let fid = window.localStorage.getItem("userid")
                wxShare({
                    title: res[0].data.title,
                    desc: res[0].data.short_title || '康来家园邀你共享健康家园',
                    link: window.location.origin + '/ydj/detail/' + this.$route.params.id + `?fid=${fid}`,
                    imgUrl: res[0].data.good_pic
                })
            }
            if (res[1].code === 1) {
                this.benefitData = res[1].data
            }
        })
    },
    computed: {
        getPrice() {
            let price = this.selectValue ? this.detailData.qyk_price : this.detailData.price
            price = (Number(price) * 100 * this.num / 100).toFixed(2)
            let pirce2 = String(price).split('.')
            return {
                num1: pirce2[0],
                num2: pirce2[1]
            }
        },
        getTotalPrice() {
            let price = this.selectValue ? this.detailData.qyk_price : this.detailData.price
            price = (Number(price) * 100 * this.num / 100)
            if (this.detailData.is_activation == 0) {
                price = (price + (this.selectValue ? Number(this.benefitData.price) : 0))
            }
            price = price.toFixed(2)

            let pirce2 = String(price).split('.')
            return {
                num1: pirce2[0],
                num2: pirce2[1]
            }
        }
    },
    methods: {
        goPay() {
            this.$refs.formRef.validate().then(async (valid) => {
                if (this.throt) {
                    this.throt = false
                    let res = await axios.post("/qykshop/create", {
                        goods_id: this.$route.params.id,
                        qyk_id: (this.selectValue && this.detailData.is_activation == 0) ? this.benefitData.good_id : '',
                        pay_num: this.num,
                        name: this.userForm.name,
                        tel: this.userForm.tel,
                        ...this.$route.query
                    })
                    if (res.code === 1) {
                        weChatPay({
                            wxPayConfig: res.data.payConfig,
                            onSuccess: () => {
                                if (this.detailData.goods_type == 1) {
                                    this.$router.push(`/ydj/pay-success/${res.data.orderSn}`)
                                } else {
                                    this.$router.push(`/ydj/pay-ydj-success/${res.data.orderSn}`)
                                }
                            },
                            onCancel: () => {
                                this.$toast.loading("支付失败")
                            },
                        })
                    }
                    this.throt = true
                }
            })
        },
        selectRadio() {
            this.selectValue = !this.selectValue
        }
    }
}
</script>

<style scoped lang="less">
.control {
    width: 100%;
    min-height: 100vh;
    background: #F5F5F5;
    padding: 10px 10px 80px;
    box-sizing: border-box;

    /deep/ .van-skeleton {
        padding: 0;
    }

    .good-box {
        background: #FEFFFF;
        border-radius: 4px;
        padding: 20px 18px 20px 8px;

        .good-box-1 {
            display: flex;

            .good-banner {
                width: 35%;
                position: relative;
                padding-top: 28%;
                border-radius: 5px;
                overflow: hidden;

                .van-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .good-info {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 10px;

                .good-title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #232323;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3; /* 这里是超出几行省略 */
                    overflow: hidden;
                }

                .good-price {
                    justify-content: flex-end;

                    .price-1 {
                        font-size: 14px;
                        font-weight: 500;
                        color: #A0A0A0;
                        text-decoration: line-through;
                        text-align: right;
                    }

                    .price-2 {
                        margin-left: 10px;
                        font-weight: 500;
                        color: #232323;
                        font-size: 14px;

                        strong {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .good-box-2 {
            margin-top: 15px;
            padding-left: 12px;

            .label {
                font-size: 14px;
                font-weight: 500;
                color: #232323;
            }

            .van-stepper {
                border: 1px solid #979797;
                border-radius: 7px;
                overflow: hidden;

                /deep/ .van-stepper__minus, /deep/ .van-stepper__plus {
                    color: #101112;
                    background: transparent !important;
                }

                /deep/ .van-stepper__minus--disabled, /deep/ .van-stepper__plus--disabled {
                    color: #c8c9cc;
                }

                /deep/ input {
                    background: transparent !important;
                    border-left: 1px solid #979797;
                    border-right: 1px solid #979797;
                    padding: 0 8px;
                    font-size: 15px;
                    box-sizing: content-box;
                }
            }
        }

    }

    .good-section {
        background: #FEFFFF;
        border-radius: 4px;
        margin-top: 15px;
        padding: 14px 0px;

        .good-section-title {
            padding: 0 20px;
            font-size: 14px;
            font-weight: bold;
            color: #232323;
            border-left: 5px solid #319D67;
            margin-bottom: 25px;
        }

        .good-section-con {
            padding: 0 25px;

            .van-form {
                .van-cell {
                    padding: 14px 0;

                    &:after {
                        left: 0;
                        width: 100%;
                        border-color: #F1F1F1;
                    }
                }

                /deep/ .van-field__label {
                    font-size: 14px;
                    font-weight: 500;
                    color: #232323;
                }
            }

            .good-info-item {
                & + .good-info-item {
                    margin-top: 25px;
                }

                .label {
                    font-size: 14px;
                    font-weight: 500;
                    color: #232323;
                }

                .value {
                    font-size: 13px;
                    font-weight: 500;
                    color: #232323;
                }
            }

            .price-amount {
                margin-top: 20px;
                position: relative;
                padding-top: 16px;
                font-size: 14px;
                text-align: right;
                span{
                    color: #ED2525;
                }
                strong {
                    color: #ED2525;
                    font-size: 18px;
                }

                &:before {
                    content: '';
                    display: block;
                    background: #F1F1F1;
                    height: 1px;
                    width: calc(100% + 20px);
                    position: absolute;
                    left: -10px;
                    top: 0px;
                }
            }
        }

    }

    .benefit-card-title {
        background: #D9FCEB;
        width: fit-content;
        font-size: 12px;
        font-weight: 500;
        color: #232323;
        padding: 2px 6px 2px 0;
        border-radius: 0px 0px 7px 7px;

        .benefit-card-icon {
            width: 30px;
        }
    }

    .benefit-card {
        background: linear-gradient(180deg, #FFEFED 0%, #FFFFFF 100%);
        border-radius: 5px;
        margin-top: 15px;
        overflow: hidden;


        .benefit-con {
            padding: 8px 20px 12px;

            .van-image {
                border-radius: 5px;
                overflow: hidden;
                margin-right: 15px;
            }

            .benefit-con-right {
                flex: 1;

                .benefit-con-1 {
                    font-size: 14px;
                    color: #232323;
                }

                .benefit-con-2 {
                    font-size: 12px;
                    font-weight: 500;
                    color: #FEFFFF;
                    background: #F54430;
                    padding: 1px 10px 4px;
                    border-radius: 20px;
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-top: 5px solid #F54430;
                        border-bottom: 5px solid transparent;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        right: 5px;
                        bottom: -8px;
                    }
                }

                .benefit-con-3 {
                    margin-top: 10px;

                    .benefit-price-1 {
                        font-size: 14px;
                        color: #F54430;
                        margin-right: 10px;

                        strong {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .footer-box {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px -3px 10px 0px rgba(224, 224, 224, 0.27);
        padding: 10px 25px 10px 20px;
        box-sizing: border-box;

        .footer-1 {
            font-size: 14px;
            font-weight: 500;
            color: #232323;

            span {
                color: #ED2525;
            }

            strong {
                font-size: 23px;
            }
        }

        .footer-2 {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            margin-top: 3px;
        }

        .footer-btn {
            background: linear-gradient(90deg, #FF6435 0%, #ED2C2C 100%);
            border-radius: 23px;
            width: 50%;
            padding: 12px 0;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
            font-size: 15px;
        }
    }
}
</style>
